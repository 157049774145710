import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useGlobalContext } from "../context/context"
import { axiosGetRequest } from "../API/API"
import Loader from "../components/item/loader"
import Header from "../components/regions/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import FieldButton from "../components/field/fieldButton"
import "../styles/components/block--page-top-content--payment-info.scss"
import {
  clearLocalStorage,
  getLocalStorage,
  removeFromLocalStorage,
} from "../localStorageService/localStorageService"
import { checkSessionToken } from "../utils/checkSessionToken"

const SIDELINE_URL = process.env.GATSBY_SIDELINE_URL

const SuccessPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [intervalId, setIntervalId] = useState("")
  const [status, setStatus] = useState(null)
  const { setUserInfo } = useGlobalContext()
  const clientDomain = getLocalStorage("client-domain")
  const clientEmail = getLocalStorage("client-email")

  const returnToHomePage = () => {
    navigate("/")
    clearLocalStorage()
  }

  useEffect(() => {
    if (!checkSessionToken()) {
      navigate("/")
    }
  }, [])

  const checkIfInstanceExsists = async clientDomain => {
    setIsLoading(true)
    axiosGetRequest("getDomain", clientDomain)
      .then(response => {
        let provisionerStatus = Number(response.data.provisioner_status)
        if (provisionerStatus === 2 || provisionerStatus === 3) {
          setStatus(provisionerStatus)
          removeFromLocalStorage("sideline-client-info")
          setIsLoading(false)
          setUserInfo({})
        }
      })
      .catch(error => {
        if (error.response.status === 405) {
          console.error(error)
          setIsLoading(false)
          setStatus(2)
        }
      })
  }

  useEffect(() => {
    checkIfInstanceExsists(clientDomain)
    if (status === null) {
      let retryRequest = setInterval(() => {
        checkIfInstanceExsists(clientDomain)
      }, 3000)
      setIntervalId(retryRequest)
    } else if (status === 2) {
      clearInterval(intervalId)
    } else if (status === 3) {
      clearInterval(intervalId)
    }
  }, [status])

  return (
    <>
      <Header />
      <Layout>
        <Seo title="Successful payment" pageClass="page--success" />
        {!checkSessionToken() ? (
          <>
            <Container classes="block--page-top-content block--page-top-content--success"></Container>
          </>
        ) : (
          <>
            <Container classes="block--page-top-content block--page-top-content--success">
              {isLoading ? (
                <Loader loaderMsg="Processing..." />
              ) : (
                <>
                  <FieldText>
                    <h1 className="text text--centered">
                      {status === 2
                        ? "Your account was successfully created!"
                        : "Something went wrong :("}
                    </h1>

                    <h2 className="text text--centered">
                      {status === 2
                        ? "Thank you for your subscription!"
                        : "There has been an error during the application instalation process..."}
                    </h2>

                    {status === 2 && (
                      <h3 className="text text--centered">
                        An email with your password has been sent to{" "}
                        {clientEmail}.
                      </h3>
                    )}

                    <p className="text text--centered">
                      {status === 2
                        ? "Check your inbox to log into your Sideline account or click on the link below."
                        : "Contact the administration for further information or try again later"}
                    </p>
                  </FieldText>

                  {status === 2 && (
                    <FieldButton>
                      <a
                        className="text text--red text--centered"
                        target="_blank"
                        href={`https://${clientDomain}.${SIDELINE_URL}`}
                      >
                        {`${clientDomain}.${SIDELINE_URL}`}
                      </a>
                    </FieldButton>
                  )}

                  <FieldButton>
                    <button
                      onClick={returnToHomePage}
                      className="button button--big button--red"
                    >
                      Return to Home page
                    </button>
                  </FieldButton>
                </>
              )}
            </Container>
          </>
        )}
      </Layout>
    </>
  )
}
export default SuccessPage
